import { reactive } from 'vue';
import plz_energie from './plz_energie.json';
import plz_pumpe from './plz_pumpe.json';

export const store = reactive({
    customer: {
        firstname: '',
        lastname: '',
        street: '',
        zip: '',
        city: '',
        mail: '',
        phone: '',
        agree: false
    },
    building: {
        zip: '',
        type: '',
        owner: '',
        monument: '',
        baujahr: '',
        renovation: '',
        wand: '',
        dach: '',
        heating_installation: '',
        roadmap: '',
    },
    message: '',
    energieberatungspaket: false,
    history: [],
    installation_alternative: false,
    uuid: '',
    current: 0,
    current_step: 0,
    loaded: false,
    plz_energie: plz_energie,
    plz_pumpe: plz_pumpe,
    zipCodes: [...plz_pumpe, ...plz_energie],
    success_zip_pumpe: false,
    success_zip_energie: false,
})

