<template>
  <div class="formdata renovation">
    <h2>Wurde  in den letzten 20 Jahren eine Sanierung durch eine <span class="hervorhebung">Dämmung der Außenwände</span> vorgenommen?</h2>


    <div class="form-optiongroup">

      <div class="form-option">
        <input type="radio" id="renovation_wand_true" value="ja" v-model="store.building.wand" @click="chosen('ja')" />
        <label for="renovation_wand_true">
          <img src="../assets/icons/ja.png" />
          <p>Ja</p>
        </label>
      </div>

      <div class="form-option">
        <input type="radio" id="renovation_wand_false" value="nein" v-model="store.building.wand" @click="chosen('nein')" />
        <label for="renovation_wand_false">
          <img src="../assets/icons/nein.png" />
          <p>Nein</p>
        </label>
      </div>

    </div>
    <button class="button button_yellow right" v-if="store.building.wand" @click="submit">Weiter</button>
  </div>
</template>

<script>
import {Interpreter} from "xstate";
import { store } from '../store.js'

export default {
  name: 'StateSanierungWand',
  props: {
    clickTrackService: Interpreter,
  },
  data() {
    return {
      store,
    }
  },
  methods: {
    submit() {
      let success = true;

      this.store.message = "";

      if(success) {
        this.clickTrackService.send('SUCCESS');
      }else{
        this.store.energieberatungspaket = true;
        this.clickTrackService.send('FAILURE');
      }
    },
    chosen(value){
      this.store.building.wand = value;
      this.submit();
    }
  }
}
</script>


