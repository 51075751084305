<template>
  <div class="formdata renovation">
    <h2>Wurde  in den letzten 20 Jahren eine Sanierung des <span class="hervorhebung">Daches</span> vorgenommen?</h2>


    <div class="form-optiongroup">

      <div class="form-option">
        <input type="radio" id="renovation_dach_true" value="ja" v-model="store.building.dach" @click="chosen('ja')" />
        <label for="renovation_dach_true">
          <img src="../assets/icons/ja.png" />
          <p>Ja</p>
        </label>
      </div>

      <div class="form-option">
        <input type="radio" id="renovation_dach_false" value="nein" v-model="store.building.dach" @click="chosen('nein')" />
        <label for="renovation_dach_false">
          <img src="../assets/icons/nein.png" />
          <p>Nein</p>
        </label>
      </div>

    </div>
    <button class="button button_yellow right" v-if="store.building.dach" @click="submit">Weiter</button>
  </div>
</template>

<script>
import {Interpreter} from "xstate";
import { store } from '../store.js'

export default {
  name: 'StateSanierungDach',
  props: {
    clickTrackService: Interpreter,
  },
  data() {
    return {
      store,
      message:
          "<p>Aktuell können wir in der von Ihnen angegebenen PLZ kein Angebot erstellen und daher auch keine Beratung durchführen.</p>" +
          "<p>Wir bauen unser Angebot aber schnellstmöglich weiter aus. " +
          "Schauen Sie gern in Kürze wieder vorbei.</p>",
    }
  },
  methods: {
    submit() {
      //let success = true;

      let points = 0;

      points = this.store.building.baujahr === "old" ? points : points + 1;
      points = this.store.building.dach === "nein" ? points : points + 1;
      points = this.store.building.wand === "nein" ? points : points + 1;

      if (points >= 2 && this.store.success_zip_pumpe){
        this.store.message = "";
        this.clickTrackService.send('PUMPE');
      }else if (points < 2 && this.store.success_zip_energie){
        this.store.message = "";
        this.clickTrackService.send('ENERGIEBERATUNG');
      }else{
        this.store.message = this.message;
        this.clickTrackService.send('WRONG_PLZ');
      }

      /*
      if(success) {
        this.clickTrackService.send('SUCCESS');
      }else{
        this.store.energieberatungspaket = true;
        this.clickTrackService.send('FAILURE');
      }
      */
    },
    chosen(value){
      this.store.building.dach = value;
      this.submit();
    }
  }
}
</script>


