<template>
  <div id="hard-abort">
    <div class="message"><p v-html="store.message"></p></div>
  </div>


</template>

<script>
import {Interpreter} from "xstate";
import { store } from '../store.js'

export default {
  name: 'StateHardAbort',
  props: {
    clickTrackService: Interpreter,
  },
  data() {
    return {
      store
    }
  }
}
</script>

