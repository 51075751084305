<template>
  <div id="soft-abort">
  <div class="message"><p v-html="store.message"></p></div>
    <!--
    <form>
      <input type="text" name="firstname" placeholder="Vorname" class="name input" v-model="store.customer.firstname" />
      <input type="text" name="lastname" placeholder="Nachname" class="name input" v-model="store.customer.firstname" />
      <input type="text" name="street" placeholder="Straße / Hausnr." class="street input" v-model="store.customer.street" />
      <input type="number" name="zip" placeholder="Ihre Postleitzahl" maxlength="5" class="zipcode input" v-model="store.customer.zip" />
      <input type="text" name="city" placeholder="Ort" class="city input" v-model="store.customer.city" />
      <input type="email" name="email" placeholder="mail@example.com" class="email input" v-model="store.customer.mail" />
      <input type="text" name="phone" placeholder="0123 1234 3434" class="phone input" v-model="store.customer.phone" />
      <input type="checkbox" id="agree" name="agree" v-model="store.customer.agree" /><label for="agree">Mit der Verarbeitung meiner Daten bin ich einverstanden.</label>
    </form>
    -->
  </div>


</template>

<script>
import {Interpreter} from "xstate";
import { store } from '../store.js'

export default {
  name: 'StateSoftAbort',
  props: {
    clickTrackService: Interpreter,
  },
  data() {
    return {
      store
    }
  }

}
</script>

