<template>
  <div class="formdata building">
    <h2>Um welche Art Gebäude handelt es sich?</h2>

    <div class="form-optiongroup">

      <div v-for="(buildingType, index) in buildingTypes" :key="index" class="form-option">
        <input type="radio" v-model="selected" :value="index" :id="'building_type_'+index" @click="chosen(index)">
        <label :for="'building_type_'+index">
          <img :src="buildingType.image" />
          <p>{{buildingType.name}}</p>
        </label>
      </div>
    </div>

    <button class="button button_yellow right" v-if="selected" @click="submit">Weiter</button>
  </div>
</template>

<script>
import {Interpreter} from "xstate";
import { store } from '../store.js'

export default {
  name: 'StateBuilding',
  props: {
    clickTrackService: Interpreter,
  },
  data() {
    return {
      message:
         "<p><strong>Aktuell können wir für Ihr Mehrfamilienhaus leider keine Beratung durchführen.</strong></p>" +
          "<p>Unsere Luft-Wasser-Wärmepumpe ist momentan nur auf den effizienten Einsatz in Ein- und Zweifamilienhäusern ausgerichtet. " +
          "Für Ihr Mehrfamilienhaus können wir Ihnen daher aktuell kein Angebot erstellen. </p/>" +
          "<p>Wir bauen unser Angebot zukünftig aber weiter aus. Schauen Sie gern in Kürze wieder vorbei.</p>",
      selected: null,
      buildingTypes: [
        {
          name: "Einfamilienhaus / Zweifamilienhaus",
          image: require('../assets/icons/einfamilienhaus_BccnZFV.png'),
          success: true
        },
        {
          name: "Reihenendhaus / Reihenmittelhaus",
          image: require('../assets/icons/doppelhaus_SOwnTBe.png'),
          success: true
        },
        {
          name: "Mehrfamilienhaus",
          image: require('../assets/icons/mehrfamilienhaus_TzNYijc.png'),
          success: false
        },
      ],
      store
    }
  },
  methods: {
    submit() {
      let buildingType = this.buildingTypes[this.selected];
      this.store.building.type = buildingType.name;
      if(buildingType.success) {
        this.store.message = "";
        this.clickTrackService.send('SUCCESS');
      }else{
        this.store.message = this.message;
        this.clickTrackService.send('FAILURE');
      }
    },
    chosen(index){
      this.selected = index;
      this.submit();
    }
  }
}
</script>


