<template>
  <div class="formdata owner">
    <h2>Sind Sie Eigentümer des Hauses?</h2>

    <div class="form-optiongroup">

      <div class="form-option">
        <input type="radio" id="owner_true" value="ja" v-model="store.building.owner" @click="chosen('ja')" />
        <label for="owner_true">
          <img src="../assets/icons/ja.png" />
          <p>Ja</p>
        </label>
      </div>

      <div class="form-option">
        <input type="radio" id="owner_false" value="nein" v-model="store.building.owner" @click="chosen('nein')" />
        <label for="owner_false">
          <img src="../assets/icons/nein.png" />
          <p>Nein</p>
        </label>
      </div>

    </div>
    <button class="button button_yellow right" v-if="store.building.owner" @click="submit">Weiter</button>
  </div>


</template>

<script>
import {Interpreter} from "xstate";
import { store } from '../store.js'

export default {
  name: 'StateOwner',
  props: {
    clickTrackService: Interpreter,
  },
  data() {
    return {
      store,
      message:
          "<p>Aufgrund Ihrer Angaben zur Eigentümerschaft des Objektes können wir Ihnen aktuell leider kein Angebot erstellen und keine Beratung anbieten. Unser Angebot richtet sich aktuell nur an alleinige Hauseigentümer:innen. Eigentümergemeinschaften sind ebenfalls ausgeschlossen. </p>" +
          "<p></p>Wir bauen unser Angebot aber schnellstmöglich weiter aus. Schauen Sie gern in Kürze wieder vorbei.</p>",
    }
  },
  methods: {
    submit() {
      let success = (this.store.building.owner === "ja");

      if(success) {
        this.store.message = "";
        this.clickTrackService.send('SUCCESS');
      }else{
        this.store.message = this.message;
        this.clickTrackService.send('FAILURE');
      }
    },
    chosen(value){
      this.store.building.owner = value;
      this.submit();
    }
  }
}
</script>


