<template>
  <div class="formdata monument">
    <h2>Steht Ihr Haus unter Denkmalschutz?</h2>

    <div class="form-optiongroup">

      <div class="form-option">
        <input type="radio" id="monument_true" value="ja" v-model="store.building.monument" @click="chosen('ja')" />
        <label for="monument_true">
          <img src="../assets/icons/ja.png" />
          <p>Ja</p>
        </label>
      </div>

      <div class="form-option">
        <input type="radio" id="monument_false" value="nein" v-model="store.building.monument" @click="chosen('nein') "/>
        <label for="monument_false">
          <img src="../assets/icons/nein.png" />
          <p>Nein</p>
        </label>
      </div>

    </div>

    <button class="button button_yellow right" v-if="store.building.monument" @click="submit">Weiter</button>
  </div>


</template>

<script>
import {Interpreter} from "xstate";
import { store } from '../store.js'

export default {
  name: 'StateMonument',
  props: {
    clickTrackService: Interpreter,
  },
  data() {
    return {
      store,
      message:
          "<p>Aufgrund Ihrer Angaben zum Denkmalschutz können wir bei Ihnen leider kein Angebot erstellen. " +
          "Bitte beachten Sie hierzu die Regeln zum Denkmalschutz in Ihrem Bundesland.</p>",
    }
  },
  methods: {
    submit() {
      let success = (this.store.building.monument === "nein");

      if(success) {
        this.store.message = "";
        this.clickTrackService.send('SUCCESS');
      }else{
        this.store.message = this.message;
        this.clickTrackService.send('FAILURE');
      }
    },
  chosen(value){
    this.store.building.monument = value;
    this.submit();
    }
  }
}
</script>


