<template>
  <div class="formdata zipcode">
    <h2>In welcher PLZ steht Ihr Gebäude?</h2>
    <input type="text"
           placeholder="Ihre Postleitzahl"
           minlength="5"
           maxlength="5"
           class="zipcode input"
           name="zipcode"
           v-model="store.building.zip"
           v-on:keyup.enter="submit"
    />
    <button class="button button_yellow" v-if="zipcheck" @click="submit">Weiter</button>
      <p>Wir bieten aktuell je nach Gebäudezustand zwei verschiedene Vor-Ort-Beratungen an, die in ausgewählten Gebieten im Norden, Osten und Westen Deutschlands verfügbar sind. Die verfügbaren PLZ-Gebiete werden laufend weiter ausgebaut.</p>
    <div class="plz-karte">
      <img src="../assets/VAT_Deutschlandkarte.png" width="250"/>
    </div>




</div>



</template>

<script>
import {Interpreter} from "xstate";
import { store } from '../store.js'


export default {
  name: 'StateZipCode',
  props: {
    clickTrackService: Interpreter,
  },
  data() {
    return {
      store,
      message:
          "<p>Aktuell können wir in der von Ihnen angegebenen PLZ kein Angebot erstellen und daher auch keine Beratung durchführen.</p>" +
          "<p>Wir bauen unser Angebot aber schnellstmöglich weiter aus. " +
          "Schauen Sie gern in Kürze wieder vorbei.</p>",
    }
  },
  computed: {
    allowedZipCodes() {
      alert("blabla");
      console.log("Dies ist ein Test!");
      console.log(this.zipCodes);
      //console.log(test);
      return ["12345", "69117", "69115", "69118"];
    },
    zipcheck(){
     if  ( isNaN(this.store.building.zip) ) {
       return false;
     }
     if (this.store.building.zip <= 0){
       return false;
     }
     if (this.store.building.zip.length !== 5){
       return false;
     }
     return true;
    }
  },
  methods: {
    getAllowedZipcodes() {

    },
    submit() {
      let success = false;
      if (this.store.zipCodes.includes(this.store.building.zip)){
        success = true
      }

      if(success) {
        if (this.store.plz_energie.includes(this.store.building.zip)){
          this.store.success_zip_energie = true;
        }
        if (this.store.plz_pumpe.includes(this.store.building.zip)){
          this.store.success_zip_pumpe = true;
        }
        this.store.message = "";
        this.clickTrackService.send('SUCCESS');
      }else{
        this.store.success_zip_energie = false;
        this.store.success_zip_pumpe = false;
        this.store.message = this.message;
        this.clickTrackService.send('FAILURE');
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
