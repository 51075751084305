<template>
  <div id="app_i">
    <!--
    <ul>
      <li v-for="(content, index) in store" :key="index">{{index}} : {{content}}</li>
    </ul>
    {{current.value}}
    -->
    <div v-if="currentStep" class="headlist content">
      <h1>Um Ihnen das passende Beratungsprodukt empfehlen zu können, möchten wir Ihnen 7 Fragen stellen.</h1>
      <div class="stepwizard">
        <div class="stepwizard-row" >
          <template v-for="n in 7" :key="n">
           <div v-if="n <= currentStep" class="stepwizard-step" id="stepwizard_{{n}}"><a type="button" class="btn btn-primary btn-circle">{{n}}</a></div>
            <div v-if="n > currentStep" class="stepwizard-step" id="stepwizard_{{n}}"><a type="button" class="btn btn-default btn-circle">{{n}}</a></div>
          </template>
        </div>
      </div>
    </div>
    <div class="content" v-show="current.matches('zipcode')">
      <StateZipCode :clickTrackService="clickTrackService"/>
    </div>
    <div class="content" v-show="current.matches('building')">
      <StateBuilding :clickTrackService="clickTrackService"/>
    </div>
    <div class="content" v-show="current.matches('owner')">
      <StateOwner :clickTrackService="clickTrackService"/>
    </div>
    <div class="content" v-show="current.matches('monument')">
      <StateMonument :clickTrackService="clickTrackService"/>
    </div>
    <div class="content" v-show="current.matches('sanierung_wand')">
      <StateSanierungWand :clickTrackService="clickTrackService"/>
    </div>
    <div class="content" v-show="current.matches('sanierung_dach')">
      <StateSanierungDach :clickTrackService="clickTrackService"/>
    </div>
    <div class="content" v-show="current.matches('construction_big')">
      <StateConstructionBig :clickTrackService="clickTrackService"/>
    </div>


    <div class="content" v-show="current.matches('finish_energieberatung')">
      <StateFinishEnergieberatung :clickTrackService="clickTrackService" />
    </div>
    <div class="content" v-show="current.matches('finish_installation')">
      <StateFinishInstallation :clickTrackService="clickTrackService" />
    </div>

    <div class="content" v-show="current.matches('soft_abort')">
      <StateSoftAbort :clickTrackService="clickTrackService" />
    </div>
    <div class="content" v-show="current.matches('hard_abort')">
      <StateHardAbort :clickTrackService="clickTrackService" />
    </div>

    <button v-if="hasHistory && !current.matches('finish_energieberatung') &&!current.matches('finish_installation')"
            class="button button_ghost" @click="back">Zurück</button>


    <div v-if="current.matches('finish_energieberatung')" class="solution">


    </div>

    <div v-if="current.matches('finish_installation')" class="solution">



    </div>

  </div>
</template>

<style>
@import './assets/style.css';

</style>

<script>
import { createMachine, interpret } from "xstate";
import StateSanierungDach from "@/components/StateSanierungDach";
import StateSanierungWand from "./components/StateSanierungWand.vue";
import StateZipCode from './components/StateZipCode.vue';
import StateBuilding from './components/StateBuilding.vue';
import StateOwner from './components/StateOwner.vue';
import StateMonument from './components/StateMonument.vue';
import StateConstructionBig from './components/StateConstructionBig.vue';

import StateFinishEnergieberatung from './components/StateFinishEnergieberatung.vue';
import StateFinishInstallation from './components/StateFinishInstallation.vue';
import StateSoftAbort from './components/StateSoftAbort.vue';
import StateHardAbort from './components/StateHardAbort.vue';


import { uuid } from 'vue-uuid';
import axios from 'axios';
import { store } from './store.js';
import { ajaxUrl }  from './globals.js';

//import { clickTrackMachine, clickTrackService } from "./statemachine";
//import { auth } from './globals.js';


const clickTrackMachine = createMachine({
  id: "clickTrack",
  initial: "building",
  states: {
    zipcode: {
      on: { SUCCESS: "owner", FAILURE: "soft_abort"},
    },
    building: {
      on: { SUCCESS: "zipcode", FAILURE: "soft_abort"}
    },
    owner: {
      on: { SUCCESS: "construction_big", FAILURE: "hard_abort" }
    },
    monument: {
      on: { SUCCESS: "sanierung_wand", FAILURE: "hard_abort" }
    },
    construction_big: {
      on: { OLD: "monument",  MEDIUM: "sanierung_wand", YOUNG: "finish_installation", WRONG_PLZ: "soft_abort", NEW: "soft_abort" }
    },
    sanierung_wand: {
      on: {SUCCESS: "sanierung_dach"}
    },
    sanierung_dach: {
      on: {ENERGIEBERATUNG: "finish_energieberatung", PUMPE: "finish_installation",  WRONG_PLZ: "soft_abort"}
    },
    soft_abort: {},
    hard_abort: {},
    finish_energieberatung: {},
    finish_installation: {},
  }
});




export default {
  name: "App",
  components: {
    StateSanierungDach,
    StateSanierungWand,
    StateZipCode,
    StateBuilding,
    StateOwner,
    StateMonument,
    StateConstructionBig,
    StateFinishEnergieberatung,
    StateFinishInstallation,
    StateSoftAbort,
    StateHardAbort,
  },
  data() {
    return {
      clickTrackService: interpret(clickTrackMachine),
      current: clickTrackMachine.initialState,
      energieberatungsbutton: "Mehr zur Energieberatung",
      wärmepumpenberatungsbutton: "Wärmepumpen-Beratung bestellen",
      uuid: uuid.v4(),
      steps: 0,
      store
    };
  },
  computed: {
    hasHistory() {
      return (this.store.history.length > 1);
    },
    currentStep(){
      if (this.current.matches('building')){
       return  1;
      }
      if (this.current.matches('zipcode')){
        return 2;
      }
      if (this.current.matches('owner')){
        return 3;
      }
      if (this.current.matches('construction_big')){
        return 4;
      }
      if (this.current.matches('monument')){
        return 5;
      }
      if (this.current.matches('sanierung_wand')){
        return 6;
      }
      if (this.current.matches('sanierung_dach')){
        return 7;
      }

      return 0;
    }
  },
  created() {

    this.store.current = 1;
    this.clickTrackService
        .onTransition(state => {
          this.storeData(state.value, this.current.value, "transition");
          this.store.history.push(this.current);
          this.current = state;
          if (this.store.loaded) {
            window.scrollTo({top: 0, left: 0, behavior:'smooth'});
            //document.body.scrollTo({behavior: 'smooth'});
            window.parent.postMessage('scrolling', '*');
          }
          this.store.loaded = true;
        })
        .start();
  },
  methods: {

    async storeData(to, from, type ){
      let title = this.generateDiscriptiveTitle(from, to);
      let result = await axios.post(ajaxUrl, {
        request: 1,
        uuid: this.uuid,
        steps: this.steps,
        from_view: from,
        to_view: to,
        title: title,
        type: type,
      }).catch(error => (console.log(error)));
      this.steps ++;


      if ( "zipcode" === from ) {
        if ("soft_abort" === to || "owner" === to) {
          let status = ("soft_abort" === to) ? "ungültig" : "gültig";
          axios.post(ajaxUrl, {
            request: 2,
            uuid: this.uuid,
            zipcode: this.store.building.zip,
            status: status,
          }).catch(error => (console.log(error)));
        }
      }
      return result;
    },
    back() {
     let lastState = this.store.history.pop();
     this.clickTrackService.stop();
      this.clickTrackService
          .onTransition(state => {
            this.storeData(state.value, this.current.value, 'transition');
            this.store.history.push(this.current);
            this.current = state;
            window.scrollTo({top: 0, left: 0, behavior:'smooth'});
            //document.body.scrollTo({top: 0, left: 0, behavior:'smooth'});
            window.parent.postMessage('scrolling', '*');
          })
          .start(lastState);
      this.store.history.pop();
      this.store.current --;
    },
    generateDiscriptiveTitle(transitionFrom, transitionTo){
      let description = "";
      switch (transitionTo){
        case "building":
          description = "Frage: Art des Gebäudes";
          break;
        case "zipcode":
          description = "Frage: PLZ Eingabe";
          break;
        case "owner":
          description = "Frage: Eigentümer"
          break;
        case "monument":
          description = "Frage: Denkmalschutz";
          break;
        case "construction_big":
          description = "Frage: Baujahr des Hauses";
          break;
        case "sanierung_wand":
          description = "Frage: Sanierung Wand";
          break;
        case "sanierung_dach":
          description = "Frage: Sanierung Dach";
          break;
        case "soft_abort":
          switch (transitionFrom) {
            case "building":
              description = "Abbruch: Mehrfamilienhaus";
              break;
            case "zipcode":
              description = "Abbruch: PLZ nicht verfügbar";
              break;
            case "construction_big":
              if (this.store.building.baujahr == "new") {
                description = "Abbruch: Neubauobjekt";
              }else{
                description = "Abbruch: Produkt in PLZ nicht verfügbar";
              }
              break;
            case "sanierung_dach":
              description = "Abbruch: Produkt in PLZ nicht verfügbar";
              break;
            default:
              description = "Abbruch: Kein Angebot";
              break;
          }
          break;
        case "hard_abort":
          switch (transitionFrom){
            case "owner":
              description = "Abbruch: Kein Eigentümer";
              break;
            case "monument":
              description = "Abbruch: Denkmalschutz";
              break;
            default:
              description = "Abbruch: Kein Angebot";
              break;
          }
          break;
        case "conversion_click":
          switch (transitionFrom){
            case "finish_energieberatung":
              description = "https://online.vattenfall.de/energieberatungspaket";
              break;
            case "finish_installation":
              description = "https://www.vattenfall.de/waermepumpe-anfrage";
              break;
            default:
              description = "Externer Link";
          }
          break;
        case "finish_energieberatung":
          description = "Abschlusseite: Energieberatung";
          break;
        case "finish_installation":
          description = "Abschlussseite: Wärmepumpenberatung";
          break;
        default:
          description = "";
          break;
      }
      return description;
    }
  }
};
</script>