<template>
  <div id="finish">
  <div class="solution-top">
    <h1>Das sieht gut aus!</h1>
      Ihren Angaben entnehmen wir, dass Ihr Haus für eine Wärmepumpe gut geeignet sein könnte.
      Wir empfehlen daher, Ihren Weg zur neuen Heizung mit unserer kostenlosen <a class="inline-link" v-bind:href="pumpenberatungslink" target="_top">Wärmepumpen-Beratung</a> fortzusetzen.
    </div>
    <div class="solution">
      <div class="solution-box">
        <h1>Kostenlose Wärmepumpen-Beratung</h1>
        <div class="bottom">
          <div>
            <p class="result"><span class="important">0 €</span><br />
              <span class="important"><s>19 €</s></span><br />
              Jetzt zum Aktionspreis inkl. Steuern</p>
          </div>
         <button @click="convert" class="button button_yellow button_final">
            {{ pumpenberatungsbutton }}</button>
        </div>
        </div>
      <div class="solution-box">
    <ul>
      <li>Persönliche und qualifizierte Vor-Ort-Begehung und Analyse Ihres Hauses</li>
      <li>Rundum-Beratung zur Luft-Wasser-Wärmepumpe (Voraussetzungen, Kosten, Fördermöglichkeiten, Funktionsweise und Effizienz)</li>
      <li>Informationen zum Installationsprozess der Wärmepumpe durch Vattenfall und seine Fachpartner:innen</li>
      <li><span class="important">Bei Eignung Ihres Hauses:</span> Unverbindliches Angebot zum Kauf einer Wärmepumpe (inkl. Installation, Entsorgung Altgerät und Förderservice im Komplettangebot) mit detaillierter Kostenaufstellung, optional auch Wartungsservice und Wärmestrom</li>
    </ul>

        </div>
  </div>
  </div>


</template>

<script>
import {Interpreter} from "xstate";
import { store } from '../store.js'

export default {
  name: 'StateFinishInstallation',
  props: {
    clickTrackService: Interpreter,
  },
  data() {
    return {
      store,
      pumpenberatungsbutton: "Kostenlose Beratung sichern",
      pumpenberatungslink: "https://www.vattenfall.de/waermepumpe-anfrage",
    }
  },
  methods:{
    async convert(){
      await  this.$root.storeData('conversion_click', 'finish_installation', 'link');
      this.open();
    },
    async open(){
      window.open(this.pumpenberatungslink, "_parent");
    }
  },

}
</script>

