<template>
  <div class="formdata construction">
    <h2>Welches Baujahr hat Ihr Haus?</h2>

    <div class="form-optiongroup">
      <div class="form-option">
        <input type="radio" id="construction_big_old" value="old" v-model="store.building.baujahr" @click="chosen('old')" />
        <label for="construction_big_old">
          <img src="../assets/icons/2c_baujahr_vor_1978.png" />
          <p>Älter als 1978</p>
        </label>
     </div>

      <div class="form-option">
        <input type="radio" id="construction_big_medium" value="medium" v-model="store.building.baujahr" @click="chosen('medium')" />
        <label for="construction_big_medium">
          <img src="../assets/icons/2c_baujahr_vor_2002.png" />
          <p>Zwischen 1978 und 2001</p>
        </label>
      </div>

      <div class="form-option">
        <input type="radio" id="construction_big_young" value="young" v-model="store.building.baujahr" @click="chosen('young')" />
        <label for="construction_big_young">
          <img src="../assets/icons/2c_baujahr_vor_2018.png" />
          <p>Zwischen 2002 und 2017</p>
        </label>
      </div>

      <div class="form-option">
        <input type="radio" id="construction_big_new" value="new" v-model="store.building.baujahr" @click="chosen('new')" />
        <label for="construction_big_new">
          <img src="../assets/icons/2c_baujahr_nach_2018.png" />
          <p>Ab 2018 oder noch im Bau</p>
        </label>
      </div>

    </div>

    <button class="button button_yellow right" v-if="store.building.baujahr" @click="submit">Weiter</button>
  </div>
</template>

<script>
import {Interpreter} from "xstate";
import { store } from '../store.js'

export default {
  name: 'StateConstructionBig',
  props: {
    clickTrackService: Interpreter,
  },
  data() {
    return {
      store,
      message:
        "<p>Aktuell können wir Ihnen leider kein Angebot erstellen. Die Förderung einer Wärmepumpe bedingt, dass der Bauantrag des Gebäudes bzw. die Bauanzeige zum Zeitpunkt der Antragstellung mindestens 5 Jahre zurück liegt. Wir bauen unser Angebot aber schnellstmöglich weiter aus.</p> " +
          "<p>Schauen Sie gern in Kürze wieder vorbei.</p>",
    }
  },
  methods: {
    submit() {
      let event = this.store.building.baujahr.toUpperCase();

      if(event === "NEW") {
        this.store.message = this.message;
      }
      if (event == "YOUNG" && !this.store.success_zip_pumpe){
        this.message =
            "<p>Aktuell können wir in der von Ihnen angegebenen PLZ kein Angebot erstellen und daher auch keine Beratung durchführen.</p>" +
            "<p>Wir bauen unser Angebot aber schnellstmöglich weiter aus. " +
            "Schauen Sie gern in Kürze wieder vorbei.</p>";
        this.store.message = this.message;
        event = "WRONG_PLZ";
      }
      this.clickTrackService.send(event);
    },
    chosen(value){
      this.store.building.baujahr = value;
      this.submit();
    }
  }
}
</script>


