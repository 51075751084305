<template>
  <div id="finish">
    <div class="solution-top">
    <h1>Möglicherweise Sanierungsmaßnahmen benötigt</h1>
    <p>
      Ihren Angaben entnehmen wir, dass Ihr Haus für den effizienten Betrieb einer Wärmepumpe möglicherweise energetische Sanierungsmaßnahmen benötigt. Wir empfehlen Ihnen daher, den Weg zu Ihrer neuen Heizung mit unserer Energieberatung fortzusetzen.
    </p>
    </div>
  <div class="solution">
    <div class="solution-box">
      <h1>Energieberatung</h1>
      <div class="bottom">
        <div class="calculation">
      <p><span class="important"> &ensp;1.689 €</span> Energieberatung</p>
          <p><span class="important">- 1.300 €</span> BAFA-Förderung iSFP (80% max. 1300 €)</p>
          <p class="result"><span class="important">&ensp;389 €</span><br />
          Gesamtpreis inkl. Steuern</p>
        </div>
        <button @click="convert" class="button button_yellow button_final">
          {{ energieberatungsbutton }}</button>
      </div>
    </div>
    <div class="solution-box">
    <ul>
      <li>Ganzheitliche Vor-Ort-Begehung und Beratung inkl. Dokumentation des energetischen Ist-Zustands Ihres Hauses</li>
      <li>Erstellung eines Energieausweises (Bedarfsausweis) für Ihr Haus</li>
      <li>Entwicklung von Sanierungsvorschlägen, u. a. bzgl. der Gebäudehülle (Wände, Dach, Fenster, Böden) und Anlagetechnik (Heizung, Warmwasser, Wärmeverteilung, Lüftung) in Form eines individuellen Sanierungsfahrplans (iSFP)</li>
      <li>Auf Wunsch Festlegung und Abstimmung der individuellen Sanierungsschritte und des Sanierungsziels mit Ihnen als Hauseigentümer:in</li>
    </ul>
    <p>Die Durchführung erfolgt durch <span class="important">unabhängige und vom BAFA zugelassene Energieberater:innen</span> unserer Fachpartner:innen.
      </p>
    </div>
  </div>
  </div>


</template>

<script>
import {Interpreter} from "xstate";
import { store } from '../store.js'

export default {
  name: 'StateFinishEnergieberatung',
  props: {
    clickTrackService: Interpreter,
  },
  data() {
    return {
      store,
      energieberatungsbutton: "Energieberatung bestellen",
      energieberatungslink: "https://online.vattenfall.de/energieberatungspaket",
    }
  },
  methods:{
    async convert(){
      await this.$root.storeData('conversion_click', 'finish_energieberatung', 'link');
      this.open();

    },
    async open(){
      window.open(this.energieberatungslink, "_parent");
    }

  },

}
</script>

